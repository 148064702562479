import * as React from 'react';
import { string, ref } from 'yup';

import I18N from 'lib/I18N';

export const PasswordValidation = string()
  .required(I18N.text('Password required'))
  .min(8, I18N.text('Password must be at least 8 characters long.'))
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_-])(?=.{8,})/, () => (
    <ul className="auth-validation__password-requirements-header">
      <I18N>Password must include at least:</I18N>
      <li className="auth-validation__password-requirements-list">
        <I18N>1 uppercase letter</I18N>
      </li>
      <li className="auth-validation__password-requirements-list">
        <I18N>1 lowercase letter</I18N>
      </li>
      <li className="auth-validation__password-requirements-list">
        <I18N>1 number</I18N>
      </li>
      <li className="auth-validation__password-requirements-list">
        <I18N>1 special character</I18N>
      </li>
    </ul>
  ));

export const RetypePasswordValidation = string()
  .required(I18N.text('Retype password required'))
  .oneOf([ref('password')], I18N.text('Passwords do not match'));

export const EmailValidation = string()
  // Custom email validation because we need to align with the backend EMAIL_PATTERN
  .matches(
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    I18N.text('Invalid email address'),
  )
  .required(I18N.text('Email required'));
